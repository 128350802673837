<template>
  <div class="wrapper_content_fuel" v-if="blocks?.length > 0">
    <div class="content_left">
      <ul>
        <li v-for="(item, index) in blocks" :key="index" @click="handleSlideTo(index)">
          <span
            :class="[activeTile == index ? 'active' : '']"
            v-html="item[locale]?.title"
          >
          </span>
        </li>
      </ul>
    </div>
    <div class="right_slider slider_feul">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in blocks" :key="index">
          <div class="item_img">
            <img :src="item.image" alt="fuel" style="width: 100%; object-fit: cover;"/>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
    props: {
      blocks: null
    },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  mounted() {
    console.log(this.blocks)
  },
  created(){
    console.log(this.data)
  },
  data() {
    return {
      // data: [
      //   {
      //     title: " Các mỏ dầu, khí với trữ lượng lớn (60 – 100 tỉ m3) ",
      //     image: require("../../assets/img/info/dk.png"),
      //   },
      //   {
      //     title: " Titan với trữ lượng khoảng 1 triệu tấn ",
      //     image: require("../../assets/img/info/dk.png"),
      //   },
      //   {
      //     title: " Khoảng 250.000 ha rừng, chủ yếu là gỗ nguyên liệu ",
      //     image: require("../../assets/img/info/dk.png"),
      //   },
      //   {
      //     title:
      //       " Vật liệu xây dựng phong phú và đa dạng (xi mang, đá vôi, đá, đất sét, cao lạnh và cát….) ",
      //     image: require("../../assets/img/info/dk.png"),
      //   },
      //   {
      //     title:
      //       " Cà phê Arabica chất lượng cao chủ yếu nằm ở Hướng Phùng, Hướng Hóa. ",
      //     image: require("../../assets/img/info/dk.png"),
      //   },
      // ],
      activeTile: 0,
      swiper: null,
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    onSlideChange()  {
      console.log(this.data)
      this.activeTile = this.swiper.activeIndex;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideTo(index) {
      this.swiper.slideTo(index);
      this.activeTile = index;
    },
  },
};
</script>
