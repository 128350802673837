import http from "@/http/http-common";
// const resource = "/api/frontend";
class InfoService {
    detailMaterials() {
        return http.get(`/quang-tri/4?type=5`);
    }
    detail() {
        return http.get(`/detail-static-page/about/4?type=5`);
    }
}

export default new InfoService();
