<template>
  <BannerTop :data="data" />
  <Tabs :data="dataTabs" :language="language"/>
  <main>
    <div class="container bg-line">
      <section class="wrapper_connect" ref="info0" id="info0">
        <div class="title_main" v-if="language && Object.keys(language).length > 0">
          <h2>{{language["home::connectability"][locale]}}</h2>
        </div>
        <!-- <div class="desc">
          <p class="txt_p">
            <span class="b_txt">
              Quảng Trị thuộc vùng Bắc Trung Bộ, là đầu mối giao thông quan
              trọng,
            </span>
            nằm ngay các tuyến giao thông huyết mạch quốc gia và là tỉnh điểm
            đầu trên Hành lang kinh tế Đông - Tây về phía Đông của Việt Nam
          </p>
        </div>
        <p class="content">
          Nối với Lào - Thái Lan - Myanmar qua cửa khẩu quốc tế Lao Bảo. Đây là
          lợi thế quan trọng tạo điều kiện đưa Quảng Trị trở thành trung tâm sản
          xuất, hậu cần, dịch vụ & thương mại trực tiếp với Campuchia, Lào &
          Đông Bắc Thái Lan; kết nối với chuỗi cung ứng thương mại các nước
          ASEAN, Đông Bắc Á, Trung Quốc, Ấn Độ.
        </p> -->
        <div class="desc">
         <p class="content" v-html="data.content"></p>
        </div>
        <div class="img_blog" id="img_blog">
          <Vue3Lottie :animationData="MapJSON" :height="500" :width="500" />
          <!-- <img src="../assets/img/info/map.svg" alt="blog" /> -->
        </div>
      </section>
    </div>
    <section class="wrapper_fuel" ref="info1" id="info1">
      <div class="container"  v-if="language && Object.keys(language).length > 0">
        <div class="title_main title" >
          <h2>{{language["home::resourcesmaterials"][locale]}}</h2>
        </div>
        <div class="desc_title">
          <p>{{language["other::alandwithdiverse"][locale]}}</p>
        </div>
        <SlideBanner :blocks="blocks" :language="language"/>
      </div>
    </section>
    <div class="container bg-line" ref="info2" id="info2">
      <section class="wrapper_cost" v-if="language && Object.keys(language).length > 0">
        <div class="title_main title">
          <h2>{{language["other::competitivelabor"][locale]}}</h2>
        </div>
        <div class="desc_title">
          <p>{{language["other::minimumsalary"][locale]}}</p>
        </div>

        <div class="wrapper_cost_item">
          <div class="item_cost">
            <div class="img_it">
              <img src="../assets/img/info/pp1.png" alt="cost" />
            </div>
            <div class="content">
              <div class="heading">
                <span>{{language["other::area1"][locale]}}</span>
                <div class="price">
                  <span class="price_usd"> USD 188 </span>
                  <span class="price_vnd"> (VND 4,420,000) </span>
                </div>
              </div>
              <div class="footing">
                <p>{{language["other::address1"][locale]}}</p>
              </div>
            </div>
          </div>
          <div class="item_cost">
            <div class="img_it">
              <img src="../assets/img/info/pp2.png" alt="cost" />
            </div>
            <div class="content">
              <div class="heading">
                <span>{{language["other::area2"][locale]}}</span>
                <div class="price">
                  <span class="price_usd"> USD 167 </span>
                  <span class="price_vnd"> (VND 3,920,000) </span>
                </div>
              </div>
              <div class="footing">
                <p>{{language["other::address2"][locale]}}</p>
              </div>
            </div>
          </div>
          <div class="item_cost">
            <div class="img_it">
              <img src="../assets/img/info/pp1.png" alt="cost" />
            </div>
            <div class="content">
              <div class="heading">
                <span>{{language["other::area3"][locale]}}</span>
                <div class="price">
                  <span class="price_usd">USD 146 </span>
                  <span class="price_vnd"> (VND 3,430,000) </span>
                </div>
              </div>
              <div class="footing">
                <p>{{language["other::address3"][locale]}}</p>
              </div>
            </div>
          </div>
          <div class="item_cost">
            <div class="img_it">
              <img src="../assets/img/info/pp2.png" alt="cost" />
            </div>
            <div class="content">
              <div class="heading">
                <span>{{language["other::area4"][locale]}}</span>
                <div class="price">
                  <span class="price_usd"> USD 131</span>
                  <span class="price_vnd"> (VND 3,070,000) </span>
                </div>
              </div>
              <div class="footing">
                <p>{{language["other::address4"][locale]}}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
  <Contact :language="language"/>
</template>

<script>
// /* global $ */
// import { mapMutations } from "vuex";
import BannerTop from "@/components/common/BannerTop.vue";
import SlideBanner from "@/components/info/SlideBanner.vue";
import Tabs from "@/components/common/Tabs.vue";
import Contact from "@/components/home/Contact.vue";
import MapJSON from '@/assets/img/info/mapvn.json';
import { mapGetters } from "vuex";
import InfoService from "@/services/InfoService";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    SlideBanner,
    BannerTop,
    Tabs,
    Contact,
  },
  created() {
    this.dataHome();
  },
  data() {
    return {
      MapJSON,
      blocks:[],
      dataTabs: [
        {
          name: "home::connectability",
          id: "info0",
        },
        {
          name: "home::resourcesmaterials",
          id: "info1",
        },
        {
          name: "home::laborcosts",
          id: "info2",
        },
      ],
      data: {
        category: "QUẢNG TRỊ",
        title: "Trung tâm thủ phủ <br>năng lượng miền Trung",
        des: "",
      },
      dataPopUp: "",
      dataSlide: "",
      itemMain: [],
      itemSub: [],
      language: [],
    };
  },
  watch: {
    "$store.state.subActive": function () {
      let ref = "info" + this.subActive;
      let el = this.$refs[ref];
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.checkScroll("info");
    this.getDetail();
    this.getMaterials();
  },
  computed: {
    ...mapGetters(["locale", "info", "subActive"]),
  },
  methods: {
    checkScroll(elem) {
      if (this.subActive >= 0) {
        let ref = elem + this.subActive;
        let el = this.$refs[ref];
        if (this.subActive >= 0) {
          this.scrollEl(el, "");
        }
      }
    },
    scrollEl(el, pointV) {
      let point = pointV ? pointV : "start";
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: point,
        });
      }
    },
    async getDetail() {
      await InfoService.detail()
        .then((resp) => {
          // console.log(resp.data.data.banner.image)
          this.data.title = resp.data.data.banner[this.locale]?.title;
          this.data.content = resp?.data?.data?.banner[this.locale]?.content;
          this.data.images = resp.data.data.banner.image.replaceAll(' ', '%20');
        })
        .catch(() => { });
    },
    async getMaterials() {
      await InfoService.detailMaterials()
        .then((resp) => {
          this.blocks = resp.data?.data?.blocks;
        })
        .catch(() => { });
    },
    async dataHome() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data;
          this.data.category = resp.data.data["menu::quangtri"][this.locale];
        })
        .catch(() => {});
    },
  },
};
</script>
